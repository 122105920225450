/* eslint-disable */
const LogoText = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1448.2 163.04"
  >
    <defs>
      <style type="text/css">
        {`
        .str0 {stroke:#1188dd;stroke-width:15.27;stroke-miterlimit:22.9256}
        .fil1 {fill:none;fill-rule:nonzero}
        .fil0 {fill:#fff;fill-rule:nonzero}
          `}
      </style>
    </defs>
    <path
      className="fil0"
      d="M77.65 155.4c-9.64,0 -18.69,-1.93 -27.17,-5.77 -8.48,-3.83 -15.91,-9.12 -22.32,-15.91 -6.41,-6.79 -11.43,-14.65 -15.06,-23.58 -3.63,-8.92 -5.46,-18.45 -5.46,-28.63 0,-10.24 1.83,-19.84 5.46,-28.77 3.63,-8.92 8.65,-16.76 15.06,-23.51 6.41,-6.75 13.84,-12.04 22.32,-15.88 8.48,-3.83 17.54,-5.73 27.17,-5.73 11.53,0 22.15,2.65 31.85,7.97 9.7,5.33 17.74,12.45 24.12,21.34l-27.27 14.65c-3.53,-4.07 -7.77,-7.33 -12.72,-9.77 -4.95,-2.44 -10.28,-3.66 -15.98,-3.66 -5.43,0 -10.52,1.12 -15.3,3.36 -4.78,2.24 -8.99,5.33 -12.62,9.29 -3.63,3.97 -6.48,8.58 -8.52,13.81 -2.04,5.22 -3.05,10.86 -3.05,16.89 0,5.97 1.02,11.57 3.05,16.79 2.04,5.22 4.88,9.81 8.52,13.77 3.63,3.97 7.84,7.09 12.62,9.36 4.78,2.27 9.87,3.43 15.3,3.43 5.7,0 11.02,-1.25 15.98,-3.73 4.95,-2.48 9.19,-5.77 12.72,-9.9l27.27 14.86c-6.38,8.82 -14.42,15.91 -24.12,21.27 -9.7,5.36 -20.32,8.04 -31.85,8.04z"
    />
    <path
      id="1"
      className="fil0"
      d="M195.5 10.28l34.4 0 51.7 142.48 -32.36 0 -8.14 -22.39 -56.79 0 -8.14 22.39 -32.36 0 51.7 -142.48zm0 89.56l34.4 0 -17.3 -47.22 -17.1 47.22z"
    />
    <polygon
      id="2"
      className="fil0"
      points="297.88,152.76 297.88,10.28 330.86,10.28 382.96,97.6 382.96,10.28 413.49,10.28 413.49,152.76 380.52,152.76 328.42,65.44 328.42,152.76 "
    />
    <polygon
      id="3"
      className="fil0"
      points="545.8,10.28 545.8,40.81 505.09,40.81 505.09,152.76 474.56,152.76 474.56,40.81 433.85,40.81 433.85,10.28 "
    />
    <polygon
      id="4"
      className="fil0"
      points="569.21,152.76 569.21,10.28 599.74,10.28 599.74,152.76 "
    />
    <polygon
      id="5"
      className="fil0"
      points="627.21,152.76 627.21,10.28 660.19,10.28 712.29,97.6 712.29,10.28 742.82,10.28 742.82,152.76 709.85,152.76 657.75,65.44 657.75,152.76 "
    />
    <path
      id="6"
      className="fil0"
      d="M833.4 155.4c-9.64,0 -18.69,-1.93 -27.17,-5.77 -8.48,-3.83 -15.94,-9.12 -22.39,-15.91 -6.45,-6.79 -11.5,-14.65 -15.17,-23.58 -3.66,-8.92 -5.5,-18.45 -5.5,-28.63 0,-10.17 1.83,-19.74 5.5,-28.66 3.66,-8.92 8.72,-16.76 15.17,-23.54 6.44,-6.79 13.91,-12.11 22.39,-15.94 8.48,-3.83 17.54,-5.73 27.17,-5.73 9.7,0 18.79,1.9 27.28,5.73 8.48,3.83 15.94,9.16 22.39,15.94 6.44,6.79 11.5,14.62 15.13,23.54 3.63,8.92 5.43,18.49 5.43,28.66 0,10.18 -1.8,19.71 -5.43,28.63 -3.63,8.92 -8.68,16.79 -15.13,23.58 -6.45,6.79 -13.91,12.08 -22.39,15.91 -8.48,3.83 -17.57,5.77 -27.28,5.77zm0 -30.53c5.43,0 10.55,-1.15 15.33,-3.43 4.78,-2.27 8.99,-5.39 12.66,-9.36 3.66,-3.97 6.55,-8.55 8.61,-13.77 2.07,-5.22 3.09,-10.82 3.09,-16.79 0,-5.97 -1.02,-11.57 -3.09,-16.79 -2.07,-5.22 -4.95,-9.84 -8.61,-13.81 -3.66,-3.97 -7.87,-7.09 -12.66,-9.36 -4.78,-2.27 -9.9,-3.39 -15.33,-3.39 -5.43,0 -10.52,1.12 -15.3,3.39 -4.78,2.27 -9.02,5.39 -12.69,9.36 -3.66,3.97 -6.51,8.59 -8.59,13.81 -2.07,5.22 -3.12,10.82 -3.12,16.79 0,5.97 1.05,11.57 3.12,16.79 2.07,5.22 4.92,9.81 8.59,13.77 3.66,3.97 7.9,7.09 12.69,9.36 4.78,2.27 9.87,3.43 15.3,3.43z"
    />
    <path
      id="7"
      className="fil0"
      d="M920.92 152.76l0 -142.48 58.01 0c7.8,0 14.86,1.97 21.17,5.9 6.31,3.93 11.33,9.19 15.03,15.81 3.7,6.61 5.53,13.91 5.53,21.85 0,6.1 -1.02,11.77 -3.06,17.03 -2.03,5.26 -4.92,9.91 -8.68,13.94 -3.76,4.04 -8.21,7.29 -13.3,9.74l33.79 58.21 -35.21 0 -32.16 -55.36 -10.58 0 0 55.36 -30.53 0zm30.53 -85.9l23.81 0c2.71,0 5.19,-0.71 7.43,-2.1 2.24,-1.39 4.04,-3.22 5.39,-5.53 1.36,-2.31 2.03,-4.85 2.03,-7.63 0,-2.78 -0.68,-5.33 -2.03,-7.63 -1.36,-2.31 -3.16,-4.17 -5.39,-5.56 -2.24,-1.39 -4.71,-2.07 -7.43,-2.07l-23.81 0 0 30.53z"
    />
    <path
      id="8"
      className="fil0"
      d="M1090.27 10.28l34.4 0 51.7 142.48 -32.36 0 -8.14 -22.39 -56.79 0 -8.14 22.39 -32.36 0 51.7 -142.48zm0 89.56l34.4 0 -17.3 -47.22 -17.1 47.22z"
    />
    <polygon
      id="9"
      className="fil0"
      points="1192.65,152.76 1192.65,10.28 1225.62,10.28 1277.73,97.6 1277.73,10.28 1308.26,10.28 1308.26,152.76 1275.29,152.76 1223.18,65.44 1223.18,152.76 "
    />
    <polygon
      id="10"
      className="fil0"
      points="1440.57,10.28 1440.57,40.81 1399.86,40.81 1399.86,152.76 1369.32,152.76 1369.32,40.81 1328.62,40.81 1328.62,10.28 "
    />
    <path
      id="11"
      className="fil1 str0"
      d="M77.65 155.4c-9.64,0 -18.69,-1.93 -27.17,-5.77 -8.48,-3.83 -15.91,-9.12 -22.32,-15.91 -6.41,-6.79 -11.43,-14.65 -15.06,-23.58 -3.63,-8.92 -5.46,-18.45 -5.46,-28.63 0,-10.24 1.83,-19.84 5.46,-28.77 3.63,-8.92 8.65,-16.76 15.06,-23.51 6.41,-6.75 13.84,-12.04 22.32,-15.88 8.48,-3.83 17.54,-5.73 27.17,-5.73 11.53,0 22.15,2.65 31.85,7.97 9.7,5.33 17.74,12.45 24.12,21.34l-27.27 14.65c-3.53,-4.07 -7.77,-7.33 -12.72,-9.77 -4.95,-2.44 -10.28,-3.66 -15.98,-3.66 -5.43,0 -10.52,1.12 -15.3,3.36 -4.78,2.24 -8.99,5.33 -12.62,9.29 -3.63,3.97 -6.48,8.58 -8.52,13.81 -2.04,5.22 -3.05,10.86 -3.05,16.89 0,5.97 1.02,11.57 3.05,16.79 2.04,5.22 4.88,9.81 8.52,13.77 3.63,3.97 7.84,7.09 12.62,9.36 4.78,2.27 9.87,3.43 15.3,3.43 5.7,0 11.02,-1.25 15.98,-3.73 4.95,-2.48 9.19,-5.77 12.72,-9.9l27.27 14.86c-6.38,8.82 -14.42,15.91 -24.12,21.27 -9.7,5.36 -20.32,8.04 -31.85,8.04z"
    />
    <path
      id="12"
      className="fil1 str0"
      d="M195.5 10.28l34.4 0 51.7 142.48 -32.36 0 -8.14 -22.39 -56.79 0 -8.14 22.39 -32.36 0 51.7 -142.48zm0 89.56l34.4 0 -17.3 -47.22 -17.1 47.22z"
    />
    <polygon
      id="13"
      className="fil1 str0"
      points="297.88,152.76 297.88,10.28 330.86,10.28 382.96,97.6 382.96,10.28 413.49,10.28 413.49,152.76 380.52,152.76 328.42,65.44 328.42,152.76 "
    />
    <polygon
      id="14"
      className="fil1 str0"
      points="545.8,10.28 545.8,40.81 505.09,40.81 505.09,152.76 474.56,152.76 474.56,40.81 433.85,40.81 433.85,10.28 "
    />
    <polygon
      id="15"
      className="fil1 str0"
      points="569.21,152.76 569.21,10.28 599.74,10.28 599.74,152.76 "
    />
    <polygon
      id="16"
      className="fil1 str0"
      points="627.21,152.76 627.21,10.28 660.19,10.28 712.29,97.6 712.29,10.28 742.82,10.28 742.82,152.76 709.85,152.76 657.75,65.44 657.75,152.76 "
    />
    <path
      id="17"
      className="fil1 str0"
      d="M833.4 155.4c-9.64,0 -18.69,-1.93 -27.17,-5.77 -8.48,-3.83 -15.94,-9.12 -22.39,-15.91 -6.45,-6.79 -11.5,-14.65 -15.17,-23.58 -3.66,-8.92 -5.5,-18.45 -5.5,-28.63 0,-10.17 1.83,-19.74 5.5,-28.66 3.66,-8.92 8.72,-16.76 15.17,-23.54 6.44,-6.79 13.91,-12.11 22.39,-15.94 8.48,-3.83 17.54,-5.73 27.17,-5.73 9.7,0 18.79,1.9 27.28,5.73 8.48,3.83 15.94,9.16 22.39,15.94 6.44,6.79 11.5,14.62 15.13,23.54 3.63,8.92 5.43,18.49 5.43,28.66 0,10.18 -1.8,19.71 -5.43,28.63 -3.63,8.92 -8.68,16.79 -15.13,23.58 -6.45,6.79 -13.91,12.08 -22.39,15.91 -8.48,3.83 -17.57,5.77 -27.28,5.77zm0 -30.53c5.43,0 10.55,-1.15 15.33,-3.43 4.78,-2.27 8.99,-5.39 12.66,-9.36 3.66,-3.97 6.55,-8.55 8.61,-13.77 2.07,-5.22 3.09,-10.82 3.09,-16.79 0,-5.97 -1.02,-11.57 -3.09,-16.79 -2.07,-5.22 -4.95,-9.84 -8.61,-13.81 -3.66,-3.97 -7.87,-7.09 -12.66,-9.36 -4.78,-2.27 -9.9,-3.39 -15.33,-3.39 -5.43,0 -10.52,1.12 -15.3,3.39 -4.78,2.27 -9.02,5.39 -12.69,9.36 -3.66,3.97 -6.51,8.59 -8.59,13.81 -2.07,5.22 -3.12,10.82 -3.12,16.79 0,5.97 1.05,11.57 3.12,16.79 2.07,5.22 4.92,9.81 8.59,13.77 3.66,3.97 7.9,7.09 12.69,9.36 4.78,2.27 9.87,3.43 15.3,3.43z"
    />
    <path
      id="18"
      className="fil1 str0"
      d="M920.92 152.76l0 -142.48 58.01 0c7.8,0 14.86,1.97 21.17,5.9 6.31,3.93 11.33,9.19 15.03,15.81 3.7,6.61 5.53,13.91 5.53,21.85 0,6.1 -1.02,11.77 -3.06,17.03 -2.03,5.26 -4.92,9.91 -8.68,13.94 -3.76,4.04 -8.21,7.29 -13.3,9.74l33.79 58.21 -35.21 0 -32.16 -55.36 -10.58 0 0 55.36 -30.53 0zm30.53 -85.9l23.81 0c2.71,0 5.19,-0.71 7.43,-2.1 2.24,-1.39 4.04,-3.22 5.39,-5.53 1.36,-2.31 2.03,-4.85 2.03,-7.63 0,-2.78 -0.68,-5.33 -2.03,-7.63 -1.36,-2.31 -3.16,-4.17 -5.39,-5.56 -2.24,-1.39 -4.71,-2.07 -7.43,-2.07l-23.81 0 0 30.53z"
    />
    <path
      id="19"
      className="fil1 str0"
      d="M1090.27 10.28l34.4 0 51.7 142.48 -32.36 0 -8.14 -22.39 -56.79 0 -8.14 22.39 -32.36 0 51.7 -142.48zm0 89.56l34.4 0 -17.3 -47.22 -17.1 47.22z"
    />
    <polygon
      id="20"
      className="fil1 str0"
      points="1192.65,152.76 1192.65,10.28 1225.62,10.28 1277.73,97.6 1277.73,10.28 1308.26,10.28 1308.26,152.76 1275.29,152.76 1223.18,65.44 1223.18,152.76 "
    />
    <polygon
      id="21"
      className="fil1 str0"
      points="1440.57,10.28 1440.57,40.81 1399.86,40.81 1399.86,152.76 1369.32,152.76 1369.32,40.81 1328.62,40.81 1328.62,10.28 "
    />
  </svg>
);

export default LogoText;
