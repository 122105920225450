/* eslint-disable */

const LogoQuad = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
  >
    <style>
      {`
        .logo_q_utensils { fill:#18d }
        .logo_q_c { fill:#fff;stroke:#18d;stroke-width:8% }
      `}
    </style>
    <path
      shapeRendering="optimizeQuality"
      id="fork"
      d="M171.07,115.44c9.11,58.87,17.81,169.22,17.81,226.41,0,70.29-14.31,69.45-55.93,86.07l15.34,438.8c0,23.26-17.46,42.28-38.77,42.28a36.73,36.73,0,0,1-5.62-.61v-.23c-18.65-3-33.16-20.3-33.16-41.44l15.34-438.8c-41.63-16.61-55.94-15.78-55.94-86.07,0-57.18,8.63-168.84,17.59-226.41h9.8l2.25,248.18H80.14l5.11-248.16h9.59L99.3,363.62h20.37l4.89-248.16h10.55l4.55,248.18H160l1.75-248.18h9.26Z"
      className="logo_q_utensils"
    />
    <path
      shapeRendering="optimizeQuality"
      id="knife"
      d="M993.67,139.3V866.85c0,19.48-12.49,35.7-29.19,40.44v.18a35.9,35.9,0,0,1-9.86,1.53c-21.47,0-41.55-19.11-39-42.16,7.55-69.4,46.41-317.5,25.59-377-11.91-34-38.4-9.51-53.94-98.54,0-189.88,44.87-257.74,76-273.58.43-.21.86-.25,1.3-.45,13.68-5.81,29.18,5.25,29.18,22Z"
      className="logo_q_utensils"
    />
    <path
      id="C"
      shapeRendering="optimizeQuality"
      d="M554.73,871q-70.26,0-132.11-28a341.16,341.16,0,0,1-108.51-77.35Q267.34,716.15,240.88,651T214.33,511.81q0-74.71,26.55-139.87t73.23-114.3a343.16,343.16,0,0,1,108.51-77.18q61.85-27.94,132.11-27.88,84.12,0,154.86,38.77A349.41,349.41,0,0,1,826.86,295.09l-132.6,71.24a195.24,195.24,0,0,0-61.85-47.5,177.94,177.94,0,0,0-152.06-1.48A193.73,193.73,0,0,0,419,362.54a213.47,213.47,0,0,0-41.39,67.12q-14.85,38.1-14.85,82.13,0,43.55,14.85,81.64a212.6,212.6,0,0,0,41.39,67,197.13,197.13,0,0,0,61.35,45.52,174.94,174.94,0,0,0,152.06-1.49,194.56,194.56,0,0,0,61.85-48.15l132.6,72.24q-46.51,64.32-117.27,103.41T554.73,871Z"
      className="logo_q_c"
    />
  </svg>
);

export default LogoQuad;
