export type ConformaValidatorParams = Record<string, any>;

export type ConformaValidator = (
  value: any,
  params?: ConformaValidatorParams
) => boolean | string;

interface KeyValueObject {
  [index: string]: string | any;
}

type ValidatorKey = keyof typeof VALIDATOR;

type ValidationObject = Record<ValidatorKey, ConformaValidator>;

export type ConformaValidationList = Array<
  ValidatorKey | ConformaValidator | [ValidatorKey, ConformaValidatorParams]
>;

export type ConformaValidation =
  | keyof ValidationObject
  | ConformaValidator
  | ConformaValidationList;

export type ConformaValidationFields = Record<string, ConformaValidation>;

interface ValidationError {
  error: string;
  path: string;
}

const { isArray } = Array;
const isEmail =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const isIPv4 = /\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}/;
const isURL = /^(([^:/?#]+):)+(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;
const htmlChars: { [index: string]: string } = {
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
  '&amp;': '&'
};

const reversedEscapeChars: { [index: string]: string } = Object.keys(
  htmlChars
).reduce((list, char) => ({ ...list, [char]: htmlChars[char] }), {});

const MSG: KeyValueObject = {
  email: __('alert.error.invalid.email'),
  max: __('alert.error.value.is.to.big'),
  min: __('alert.error.value.is.to.small'),
  minText: __('alert.error.text.is.to.min'),
  required: __('alert.error.required.field'),
  regex: __('alert.error.invalid.value'),
  checked: __('alert.error.filed.must.be.checked'),
  between: __('alert.error.must.between'),
  date: __('alert.error.invalid.date'),
  alpha: __('alert.error.only.letter'),
  alnum: __('alert.error.only.letter.and.number'),
  number: __('alert.error.only.number'),
  string: __('alert.error.only.letter.and.number'),
  ipv4: __('alert.error.invalid.ip.address'),
  url: __('alert.error.invalid.url')
};

const FILTER = {
  escapeHtml(value: string) {
    return String(value).replace(/[&<>"']/g, (m) => reversedEscapeChars[m]);
  },

  bool(value: string | boolean | number) {
    if (typeof value === 'string') {
      return (
        ['true', 'yes', 'on', '1'].indexOf(value.toLowerCase().trim()) >= 0
      );
    }

    return value === true || value === 1;
  }
};

export const VALIDATOR = {
  alpha(value: any, { space = false }) {
    const regex = space
      ? /^[a-z -\xDF-\xF6\xF8-\xFF]+$/i
      : /^[a-z\xDF-\xF6\xF8-\xFF]+$/i;

    return regex.test(String(value));
  },
  alnum(value: any, { space = false }) {
    const regex = space
      ? /^[a-z0-9 -\xDF-\xF6\xF8-\xFF]+$/i
      : /^[a-z0-9\xDF-\xF6\xF8-\xFF]+$/i;

    return regex.test(String(value));
  },
  number(value: any) {
    return /^[\d,.]+$/.test(String(value));
  },
  string(value: any) {
    return typeof value === 'string';
  },
  date(value: any) {
    return !isNaN(Date.parse(String(value))); // eslint-disable-line
  },
  required(value: any) {
    return typeof value !== 'undefined' && value !== null && value !== '';
  },
  email(value: any) {
    return isEmail.test(String(value));
  },
  url(value: any) {
    return isURL.test(String(value));
  },
  min(value: any, { min }: { min: number }) {
    return Number(value) >= min;
  },
  minText(value: any, { min }: { min: number }) {
    return String(value).length >= min;
  },
  max(value: any, { max }: { max: number }) {
    return Number(value) <= max;
  },
  between(value: any, { min, max }: { min: number; max: number }) {
    return Number(value) >= min && Number(value) <= max;
  },
  checked(value: any, { check = true }) {
    return FILTER.bool(value) === check;
  },
  ipv4(value: any) {
    return isIPv4.test(String(value));
  },
  empty(value: any) {
    let check = false;

    if (Array.isArray(value) && !value.length) {
      check = true;
    } else if (value instanceof Date) {
      check = false;
    } else if (
      value instanceof Object &&
      !(value instanceof Function) &&
      !Object.keys(value).length
    ) {
      check = true;
    } else {
      check = [undefined, '', null, '0', 0, false].includes(value);
    }

    return check;
  }
};

const IGNORE = ['required', 'empty', '__FUNC'];

export class Conform {
  private readonly __values: KeyValueObject = {};
  private __validate: KeyValueObject = {};
  private __error: Array<ValidationError> = [];

  constructor(data: object) {
    this.__values = JSON.parse(JSON.stringify(data));
    this.__error = [];
    this.__validate = {};
  }

  /**
   * @param {object} validate
   * @return {Conform}
   */
  valid(validate: ConformaValidationFields = {}) {
    this.__validate = Object.keys(validate).reduce(
      (list: { [index: string]: any }, path: string) => {
        const toValidate: Array<ValidatorKey> = ([] as any).concat(
          validate[path]
        );
        const validators = list[path] || {};

        list[path] = toValidate.reduce((vlist, validator) => {
          if (VALIDATOR[validator]) {
            vlist[validator] = {
              validator: VALIDATOR[validator],
              msg: MSG[validator]
            };
          } else if (typeof validator === 'function') {
            vlist.__FUNC = (vlist.__FUNC || []).concat({
              validator
            });
          } else if (
            typeof validator === 'object' &&
            Array.isArray(validator)
          ) {
            const vpath: ValidatorKey = validator[0];
            // @ts-ignore
            const { msg, ...params } = validator[1];

            if (VALIDATOR[vpath]) {
              vlist[vpath] = {
                validator: VALIDATOR[vpath],
                msg: msg || MSG[vpath],
                params
              };
            }
          } else {
            console.error('Unknown Validator: ', { validator });
          }

          return vlist;
        }, validators);

        return list;
      },
      this.__validate
    );

    return this;
  }

  getValue(field: string): any {
    const path = isArray(field) ? field : field.split('.');
    // @ts-ignore
    return path.reduce(
      (curr: KeyValueObject, key: string) =>
        typeof curr !== 'undefined' && typeof curr[key] !== 'undefined'
          ? curr[key]
          : undefined,
      this.__values
    );
  }

  /**
   * @return {Promise<{ value: {Object}, errors: {Object} }>}
   */
  exec() {
    return Object.keys(this.__validate)
      .reduce((rootChain, path: string) => {
        const validators = this.__validate[path];
        const value = this.getValue(path);
        const fieldChain = Promise.resolve();

        if (validators.required && !VALIDATOR.required(value)) {
          this.__error.push({ error: validators.required.msg, path });
        } else if (validators.empty && VALIDATOR.empty(value)) {
          // no validate if value is empty
        } else if (validators.__FUNC) {
          validators.__FUNC.forEach(
            ({ validator }: { validator: ConformaValidator }) => {
              fieldChain
                // @ts-ignore
                .then(() => validator && validator.call(this, value, path))
                .then(
                  (check) =>
                    typeof check === 'string' &&
                    this.__error.push({
                      error: Conform.msg({ path, value }, check),
                      path
                    })
                );
            }
          );
        } else {
          Object.keys(validators)
            .filter((key) => !IGNORE.includes(key))
            .forEach((key) => {
              const { validator, msg, params } = validators[key];
              fieldChain
                .then(
                  () => validator && validator.call(this, value, params || {})
                )
                .then(
                  (check) =>
                    check !== true &&
                    this.__error.push({
                      error: Conform.msg({ path, value, ...params }, msg),
                      path
                    })
                );
            });
        }

        return rootChain.then(() => fieldChain);
      }, Promise.resolve())
      .then(() =>
        this.__error.reduce((errors, { path, error }) => {
          const keys = path.split('.');

          keys.reduce(
            (
              chain: {
                [index: string]: string | never[] | Record<string, never>;
              },
              key,
              i
            ) => {
              chain[key] =
                i === keys.length - 1 ? chain[key] || [] : chain[key] || {}; // eslint-disable-line
              return chain[key];
            },
            errors
          );

          // @ts-ignore
          keys.reduce((chain, key) => chain[key], errors).push(error);

          return errors;
        }, {})
      );
  }

  static msg(placeholder: KeyValueObject, context: string) {
    return String(context).replace(/\{\{([a-zA-Z_]+)\}\}/g, (p, key) =>
      FILTER.escapeHtml(placeholder[key] || '')
    );
  }
}

export default function (data: KeyValueObject) {
  return new Conform(data);
}
