import { useState, FC, KeyboardEvent, useEffect, useRef } from 'react';
import Calendar, {
  Detail,
  ViewCallback,
  OnChangeDateRangeCallback
} from 'react-calendar';
import { Field, useField } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { padZero } from 'orm-react/helper/string';
import classNames from 'orm-react/helper/class-names';
import { getUTCDate } from 'orm-lib/helper/date';
import { getMinDate, formatDate } from 'orm-react/helper/date';

import { ModalCalendar } from 'orm-react/components/Modal';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';
import inputStyles from 'orm-react/components/Field/Input/styles.module.scss';

/**
 *
 * @tailwind react-calendar__tile--rangeStart
 */
const FieldDateRange: FC<{
  name: [string, string];
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  minDetail?: Detail;
  maxDetail?: Detail;
  onChange?: (date: [Date, Date] | [Date] | null) => void;
  onSwitchCalendar?: ViewCallback;
  accessibleDays?: string[];
  checkAccessibility?: boolean;
  deletable?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
}> = ({
  name,
  label,
  required,
  disabled,
  minDetail,
  maxDetail,
  accessibleDays,
  checkAccessibility,
  onSwitchCalendar,
  onChange = () => null,
  minDate,
  maxDate,
  deletable = false,
  placeholder
}) => {
  const id = name.join(':');
  const [showModal, setShowModal] = useState<boolean>(false);
  const { input: inputFrom } = useField(name[0]);
  const { input: inputTill } = useField(name[1]);
  const inputField = useRef<HTMLInputElement>(null);
  const lastState = useRef(showModal);

  const changeValue: OnChangeDateRangeCallback = (
    dates: [Date, Date] | [Date]
  ) => {
    inputFrom.onChange(getUTCDate(dates[0]));
    // @ts-ignore
    inputTill.onChange(getUTCDate(dates[1]));
    inputFrom.onBlur();
    inputTill.onBlur();
    onChange(dates);
  };

  const resetValue = () => {
    inputFrom.onChange(null);
    inputFrom.onBlur();
    inputTill.onChange(null);
    inputTill.onBlur();
    onChange(null);
  };

  const onFocus = () => setShowModal(true);
  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') setShowModal(!disabled && true);
  };
  const closeModal = () => setShowModal(!disabled && false);

  const values = [
    inputFrom.value ? getUTCDate(inputFrom.value) : null,
    inputTill.value ? getUTCDate(inputTill.value) : null
  ];

  const formattedValues = [
    inputFrom.value ? formatDate(getUTCDate(inputFrom.value), 'short') : null,
    inputTill.value ? formatDate(getUTCDate(inputTill.value), 'short') : null
  ];

  useEffect(() => {
    if (lastState.current && !showModal && inputField.current) {
      inputField.current.focus();
    }

    lastState.current = showModal;
  }, [showModal]);

  return (
    <>
      <FormField required={required} value={String(inputFrom.value)}>
        <FieldError name={name[0]} />
        <FieldError name={name[1]} />
        <input
          id={id}
          ref={inputField}
          className={classNames(
            inputStyles.field__input,
            deletable && 'button'
          )}
          onKeyUp={onKeyUp}
          onClick={onFocus}
          value={formattedValues.join(' - ').trim().replace(/^-|-$/, '').trim()}
          type="text"
          placeholder={placeholder}
          autoComplete="nope"
          required={required}
          disabled={disabled}
          readOnly
        />
        <FieldLabel name={id}>{label}</FieldLabel>
        {deletable && (inputFrom.value || inputTill.value) && (
          <button type="button" onClick={resetValue}>
            <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
          </button>
        )}
        <Field {...inputFrom} component="input" type="hidden" />
        <Field {...inputTill} component="input" type="hidden" />
      </FormField>
      <ModalCalendar
        show={showModal}
        onClose={closeModal}
        onChange={changeValue}
        render={(close) => (
          <Calendar
            locale="de-DE"
            onChange={close}
            minDate={getMinDate(minDate)}
            maxDate={getMinDate(maxDate)}
            minDetail={minDetail}
            maxDetail={maxDetail}
            onActiveStartDateChange={onSwitchCalendar}
            // @ts-ignore
            value={
              values[0] !== null && values[1] !== null
                ? [getUTCDate(values[0]), getUTCDate(values[1])]
                : null
            }
            selectRange
            tileDisabled={({ date }: { date: Date }) => {
              const dayKey = [
                date.getFullYear(),
                padZero(date.getMonth() + 1),
                padZero(date.getDate())
              ].join('');
              if (checkAccessibility && accessibleDays) {
                return accessibleDays.length
                  ? !accessibleDays.includes(dayKey)
                  : true;
              }

              return false;
            }}
            showWeekNumbers
          />
        )}
      />
    </>
  );
};

FieldDateRange.propTypes /* remove-proptypes */ = {
  // @ts-ignore
  name: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checkAccessibility: PropTypes.bool,
  minDetail: PropTypes.oneOf<Detail>([]),
  maxDetail: PropTypes.oneOf<Detail>([]),
  accessibleDays: PropTypes.arrayOf(PropTypes.string.isRequired),
  onSwitchCalendar: PropTypes.func,
  onChange: PropTypes.func,
  deletable: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

export default FieldDateRange;
