import {
  useRef,
  useState,
  useEffect,
  FunctionComponent,
  FocusEvent
} from 'react';
import { useForm, useField, Field } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';
import useTerror from 'orm-react/hooks/terror';
import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';

import styles from './styles.module.scss';

interface GQLResult {
  [key: string]: string | Record<string, GQLResult>;
}

const getDeepValue = (obj: GQLResult | string): string | GQLResult => {
  if (typeof obj === 'string') {
    return obj;
  }

  const key: string = Object.keys(obj)[0];

  // @ts-ignore
  return getDeepValue(obj[key]);
};

const FieldImage: FunctionComponent<{
  id: string;
  name: string;
  uploadGQL: any;
  srcPath: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  variables?: Record<string, any>;
}> = ({
  id,
  name,
  label,
  srcPath,
  required,
  className,
  uploadGQL,
  disabled,
  variables
}) => {
  const terror = useTerror();
  const form = useForm();
  const { input } = useField(name);
  const inputRef: {
    current: (HTMLElement & HTMLInputElement) | null;
  } = useRef(null);
  const [src, setSrc] = useState<string | null>(null);
  const [blob, setBlob] = useState<File | null>(null);
  const [uploadFile, { loading }] = useMutation(uploadGQL, { variables });

  function onReset() {
    setSrc(null);
    setBlob(null);
    input.onChange(null);
    form.mutators.changed(input.name);
    if (inputRef.current) inputRef.current.value = '';
  }

  const onSelectFile = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const [file] = e.target.files;

      if (file) {
        setBlob(file);
      }
    }
  };

  useEffect(() => {
    if (blob)
      uploadFile({
        variables: {
          file: blob,
          id
        }
      })
        .then(({ data }) => {
          const value = getDeepValue(data);
          input.onChange(value);
          form.mutators.changed(input.name);
          if (inputRef.current) inputRef.current.value = '';
          setBlob(null);
          setSrc(`${srcPath}/${value}`);
        })
        .catch((error) => {
          input.onChange(null);
          form.mutators.changed(input.name);
          if (inputRef.current) inputRef.current.value = '';
          setBlob(null);
          setSrc(null);
          return terror(error);
        });
  }, [blob]);

  useEffect(() => {
    setSrc(input.value ? `${srcPath}/${input.value}` : null);
  }, [input.value]);

  return (
    <FormField
      required={required}
      value={input.value}
      className={classNames(styles.field__image, className)}
    >
      <FieldError name={name} />
      <input
        id={name}
        ref={inputRef}
        type="file"
        onChange={onSelectFile}
        accept="image/*"
        required={required}
        disabled={disabled}
      />
      {!src && (
        <FieldLabel name={name}>
          <i>
            <FontAwesomeIcon icon={['fas', 'image']} />
          </i>
          <span>{label}</span>
        </FieldLabel>
      )}
      {src && (
        <>
          <div>
            <img alt={label} src={src} />
          </div>
          <button type="button" onClick={onReset}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </button>
        </>
      )}
      {loading && (
        <div className="loader">
          <div className="loader--circle" />
        </div>
      )}
      <Field name={name} type="hidden" component="input" />
    </FormField>
  );
};

FieldImage.propTypes /* remove-proptypes */ = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  srcPath: PropTypes.string.isRequired,
  uploadGQL: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string
};

FieldImage.defaultProps = {
  required: false,
  disabled: false,
  label: '',
  className: ''
};

export default FieldImage;
