import React, { useState, useRef, createContext, useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import classNames from 'orm-react/helper/class-names';
import { useLayoutEffect } from 'orm-react/hooks/isomorphic-layout-effect';

import MainNavi from '../MainNavi';
import HeaderBrand from '../Brand';
import HeaderManager from '../Manager';
import NaviSandwich from '../NaviSandwich';

const ContextHeader = createContext({
  toggleNavi: (): void => {
    // toggle sidebar with localStorage
  }
});

import './styles.scss';

/**
 *
 * @tailwind  header__button--ripple
 */
const Header = () => {
  const header = useRef<HTMLElement>(null);
  const [isActive, setActive] = useState(false);

  const toggleNavi = () => {
    const { body } = document;
    const next = !isActive;

    if (!next) {
      body.classList.remove('body__scroll--disabled');
    } else {
      body.classList.add('body__scroll--disabled');
    }

    setActive(next);
  };

  const checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current } = header;

    if (current && top && !current.classList.contains('header--active')) {
      current.classList.add('header--active');
    } else if (current && !top) {
      current.classList.remove('header--active');
    }
  };

  useLayoutEffect(() => {
    checkScroll();
    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <ContextHeader.Provider
      value={{
        toggleNavi
      }}
    >
      <StaticQuery
        query={graphql`
          query NaviTop {
            wpContent {
              generalSettings {
                url
                title
                description
              }

              menuItems(where: { location: HEADER_TOPBAR }) {
                nodes {
                  id
                  label
                  url
                  childItems {
                    nodes {
                      id
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ wpContent }) => (
          <header
            className={classNames('header', isActive && 'active')}
            ref={header}
          >
            <div className="header__container">
              <NaviSandwich toggleNavi={toggleNavi} isActive={isActive} />
              <HeaderBrand title={wpContent.generalSettings.title} />
              <ul
                className={classNames(
                  'header__navi header__navi--main',
                  isActive && 'header__navi--active'
                )}
              >
                <MainNavi wpContent={wpContent} />
              </ul>
              <HeaderManager className={isActive && 'active'} />
            </div>
          </header>
        )}
      />
    </ContextHeader.Provider>
  );
};

const useHeader = () => useContext(ContextHeader);

export default Header;

export { useHeader };
