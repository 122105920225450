import React, { FC, ReactNode } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { LayerCookieNotice } from 'orm-react/components/Layer';

import Header from '../../Header';
import Footer from '../../Footer';

import '../../../styles/import.scss';
import '../../../../.cache/elementor/merge.css';

import 'orm-react/components/Canvas/Canvas/styles.module.scss';
import './styles.scss';

/** @tailwind: #___gatsby */
const Layout: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <>
    <div className="layout">
      <Header />
      {children}
      <Footer />
    </div>
    <LayerCookieNotice
      renderLink={() => (
        <Link to="/datenschutz">{__('label.privacy.explanation')}</Link>
      )}
    />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
