import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  FC,
  ReactNode
} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import useOverlay from 'orm-react/hooks/overlay';

interface LayerContext {
  active: boolean;
  current: string | null;
  toggleLayer: (layer: string, data: object) => void;
  getData: () => object;
}

const ContextLayer = React.createContext<LayerContext>({
  active: false,
  current: null,
  toggleLayer: (layer: string, data = {}) => {
    // toggle layer and set data
  },
  getData: () => ({})
});

const ProviderLayer: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [current, setCurrent] = useState<string | null>(null);
  const [active, setActive] = useState(false);
  const currentData = useRef({});
  const { pathname } = useLocation();

  const toggleLayer = (layer: string, data = {}) => {
    if (active && current === layer) {
      setCurrent(null);
      setActive(false);
      currentData.current = {};
    } else {
      setActive(true);
      setCurrent(layer);
      currentData.current = data;
    }
  };

  const getData = () => currentData.current;

  useEffect(() => {
    setCurrent(null);
    setActive(false);
  }, [pathname]);

  useOverlay(active);

  return (
    <ContextLayer.Provider
      value={{
        active,
        current,
        toggleLayer,
        getData
      }}
    >
      {children}
    </ContextLayer.Provider>
  );
};

ProviderLayer.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired
};

const useLayer = () => useContext(ContextLayer);

export { ContextLayer, ProviderLayer, useLayer };
