export const EVENT_KEY = {
  KEY_BACKSPACE: 8,
  KEY_TAB: 'Tab',
  KEY_SPACE: 'Space',
  KEY_ENTER: 'Enter',
  KEY_ESC: 'Escape',
  KEY_LEFT: 'ArrowLeft',
  KEY_UP: 'ArrowUp',
  KEY_RIGHT: 'ArrowRight',
  KEY_DOWN: 'ArrowDown',
  KEY_DELETE: 46,
  KEY_HOME: 36,
  KEY_END: 35,
  KEY_PAGEUP: 33,
  KEY_PAGEDOWN: 34
};
