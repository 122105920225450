import { FunctionComponent, ReactElement, ReactNode, MouseEvent } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FieldLabel: FunctionComponent<{
  name: string;
  children: ReactNode;
  onClick?: (e?: MouseEvent<HTMLLabelElement>) => void;
}> = ({ name, children, onClick }): ReactElement | null =>
  children ? (
    <label
      tabIndex={-1}
      htmlFor={name}
      className={styles.field__label}
      onClick={onClick}
    >
      {children}
    </label>
  ) : null;

FieldLabel.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default FieldLabel;
