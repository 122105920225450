import './styles.scss';
import { DocumentNode } from 'graphql';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLazyQuery } from '@apollo/react-hooks';
import { createPortal } from 'react-dom';
import { useField, Field } from 'react-final-form';

import usePortal from 'orm-react/hooks/portal';
import useDebounce from 'orm-react/hooks/debounce';
import classNames from 'orm-react/helper/class-names';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';
import { FieldLoader } from 'orm-react/components/Field';

import { EVENT_KEY } from 'orm-react/helper/event';

import FieldCompletingData from '../Data';

import inputStyles from '../../Input/styles.module.scss';

export type CompletingEntry<Type> = Type;

interface CompletedResult<Type> {
  query: string;
  result: CompletingEntry<Type>[];
}

const FieldCompleting = <Type extends Record<string, any>>({
  name,
  gqlQuery,
  gqlKey,
  gqlVariables = {},
  label,
  type = 'text',
  offsetTop = 12,
  minChars = 2,
  minWidth = 260,
  fixed = false,
  required = false,
  updateTime = 300,
  onSelect,
  className,
  tabIndex,
  placeholder,
  render,
  mapper
}: {
  name: string;
  gqlKey: string;
  gqlQuery: DocumentNode;
  render: ({
    query,
    entry
  }: {
    entry: CompletingEntry<Type>;
    query: string;
  }) => ReactNode;
  mapper: (value: CompletingEntry<Type>) => string;
  gqlVariables?: Record<string, any>;
  type?: string;
  label?: string;
  placeholder?: string;
  offsetTop?: number;
  minChars?: number;
  minWidth?: number;
  updateTime?: number;
  className?: string;
  onSelect?: (value: CompletingEntry<Type>) => void;
  fixed?: boolean;
  required?: boolean;
  tabIndex?: number;
}) => {
  const { input } = useField(name);
  const [data, setData] = useState<CompletingEntry<Type>[]>([]);
  const [selected, setSelected] = useState(-1);
  const [selectedValue, setSelectedValue] = useState<Type | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [maxIndex, setMaxIndex] = useState(-1);
  const [active, setActive] = useState(false);
  const [size, setSize] = useState({
    left: '',
    top: '',
    width: ''
  });
  const portal = usePortal('app');
  const query = useRef<HTMLInputElement>(null);
  const queryValue = useDebounce(inputValue, updateTime);
  const [loadData, { loading }] = useLazyQuery<
    Record<string, CompletedResult<Type>>
  >(gqlQuery, {
    variables: {
      ...gqlVariables
    }
  });

  const selectValue = (entry: CompletingEntry<Type>) => {
    input.onChange(entry.id);

    setInputValue(mapper(entry));
    setSelectedValue(entry);
    hide();

    onSelect && onSelect(entry);
  };

  const show = () => {
    setActive(true);
  };

  const hide = () => {
    setActive(false);
  };

  const handleData = (fetched: CompletedResult<Type>) => {
    setData(fetched.result);
    setSelected(-1);
    setMaxIndex(fetched.result.length - 1);

    if (!fetched.result.length) {
      hide();
    } else {
      show();
    }
  };

  const hoverValue = (dir: 'down' | 'up') => {
    let current = selected;

    // if list is empty then do nothing
    if (!data.length) {
      return;
    }

    switch (dir) {
      case 'down':
        current = ++current > maxIndex ? 0 : current;
        break;
      case 'up':
        current = --current < 0 ? maxIndex : current;
        break;
    }

    setSelected(current);
    show();
  };

  const handleKeys = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case EVENT_KEY.KEY_ENTER:
        e.preventDefault();
        selectValue(data[selected]);
        break;
      case EVENT_KEY.KEY_ESC:
        hide();
        break;
      case EVENT_KEY.KEY_LEFT:
      case EVENT_KEY.KEY_RIGHT:
        break;
      case EVENT_KEY.KEY_DOWN:
        hoverValue('down');
        break;
      case EVENT_KEY.KEY_UP:
        hoverValue('up');
        break;
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const hoverMe = (current: number) => {
    setSelected(current);
  };

  const onFocus = () => {
    const { current } = query;

    if (current) {
      const offset = current.getBoundingClientRect();
      const doc = document.documentElement;
      const top =
        (!fixed &&
          (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)) ||
        0;

      setSize({
        left: `${
          offset.left - Math.max(offset.width, minWidth) / 2 + offset.width / 2
        }px`,
        top: `${top + offset.bottom + offsetTop}px`,
        width: `${offset.width}px`
      });
      setActive(true);
      //checkInputValue();
    }
  };

  const onBlur = () => {
    setActive(false);
  };

  const resetValue = () => {
    input.onChange(null);
    setSelected(-1);
    setSelectedValue(null);
    setInputValue('');
    setMaxIndex(-1);
    setData([]);
    hide();
  };

  const style = {
    display: data.length && active ? 'block' : 'none',
    ...size
  };

  useEffect(() => {
    const { current } = query;

    // if length not enough
    if (current && !selectedValue && current.value.length >= minChars) {
      loadData({
        variables: {
          query: current.value
        }
      }).then((result) => {
        if (result && result.data) {
          handleData(result.data[gqlKey]);
        }
      });
    } else {
      hide();
    }
  }, [queryValue, selectedValue]);

  useEffect(() => {
    if (!queryValue && !selectedValue && input.value) {
      loadData({
        variables: {
          query: input.value
        }
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data[gqlKey] &&
          result.data[gqlKey].result.length === 1
        ) {
          selectValue(result.data[gqlKey].result[0]);
        } else {
          resetValue();
        }
      });
    }
  }, [queryValue, selectedValue, input.value]);

  return (
    <FormField
      className={classNames(
        'field__completing',
        className,
        data.length && 'active'
      )}
      required={required}
      value={inputValue}
    >
      <FieldError name={name} />
      {!selectedValue ? (
        <input
          ref={query}
          type={type}
          className={inputStyles.field__input}
          title={label || placeholder}
          placeholder={placeholder}
          tabIndex={tabIndex !== null ? tabIndex : undefined}
          autoComplete="nope"
          required={required}
          value={inputValue}
          onChange={onChange}
          onKeyDown={handleKeys}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      ) : (
        <input
          type="text"
          className={inputStyles.field__input}
          tabIndex={tabIndex !== null ? tabIndex : undefined}
          value={mapper(selectedValue)}
          readOnly
        />
      )}
      <FieldLoader loading={loading} />
      {(selectedValue !== null || inputValue.length >= minChars) && (
        <button type="button" onClick={resetValue}>
          <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
        </button>
      )}
      <FieldLabel name={name}>{label}</FieldLabel>
      <Field type="hidden" component="input" name={name} />
      {createPortal(
        <div className="field__completing__dropdown" style={style}>
          <i className="field__completing__corner" />
          <div>
            <FieldCompletingData
              data={data}
              selectValue={selectValue}
              selected={selected}
              hoverMe={hoverMe}
              inputValue={inputValue}
              render={render}
            />
          </div>
        </div>,
        portal
      )}
    </FormField>
  );
};

FieldCompleting.highlight = (text: string, query: string) =>
  query
    .split(' ')
    .reduce(
      (result, key) =>
        result.replace(
          new RegExp(`(<.*>)?(${key})(<\\/.*>)?`, 'ig'),
          (rep: string) => `<strong>${rep}</strong>`
        ),
      String(text)
    );

export default FieldCompleting;
