import { FC } from 'react';
import { FieldSelectOptionList } from 'orm-react/components/Field/Select/Options';

import FieldSelectOptions from '../Options';

export interface FieldSelectOptgroup {
  name: string;
  options: FieldSelectOptionList;
}

export type FieldSelectOptgroupList = FieldSelectOptgroup[];

const FieldSelectOptgroups: FC<{
  groups: FieldSelectOptgroupList;
}> = ({ groups }) => (
  <>
    {groups.map((group) => (
      <optgroup key={group.name} label={group.name}>
        <FieldSelectOptions options={group.options} />
      </optgroup>
    ))}
  </>
);

export default FieldSelectOptgroups;
