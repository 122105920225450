import styles from './styles.module.scss';

import { ReactNode } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

const FieldGroup = ({
  children,
  className
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div className={classNames(styles.field__group, className)}>{children}</div>
);

FieldGroup.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default FieldGroup;
