import './styles.scss';

const arrowPath = `
M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z
`;

const FieldArrow = () => (
  <svg
    className="field__arrow"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d={arrowPath} />
  </svg>
);

export default FieldArrow;
