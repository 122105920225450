import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

const FieldLoader = ({ loading }: { loading: boolean }) =>
  loading ? (
    <i className={styles.field__loader}>
      <FontAwesomeIcon icon={['fas', 'rotate']} spin size="sm" />
    </i>
  ) : null;

export default FieldLoader;
