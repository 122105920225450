import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

const Link: FC<{
  href?: string;
  children: ReactNode;
}> = ({ href, children, ...props }) => (
  <a href={href} {...props}>
    {children}
  </a>
);

Link.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Link;
