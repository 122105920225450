import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { LogoQuad } from 'orm-react/components/Logo';

import './styles.scss';

const FooterInfo = () => (
  <div className="footer__info">
    <StaticQuery
      query={graphql`
        query FooterInfoQuery {
          wpContent {
            generalSettings {
              title
              description
            }
          }
        }
      `}
      render={({ wpContent }) => (
        <>
          <div
            className="footer__brand"
            title={wpContent.generalSettings.title}
          >
            <LogoQuad />
          </div>
          <div className="footer__info__text">
            {wpContent.generalSettings.description}
          </div>
        </>
      )}
    />
  </div>
);

export default FooterInfo;
