import { ReactElement, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const FieldButton: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }): ReactElement => (
  <div className={classNames(styles.field__button, className)}>{children}</div>
);

FieldButton.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default FieldButton;
