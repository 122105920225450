import React from 'react';

import FooterNavi from '../Navi';
import FooterFlat from '../Flat';
import FooterCopy from '../Copy';
import FooterInfo from '../Info';

import './styles.scss';

const Footer = () => (
  <footer>
    <div className="footer__container">
      <FooterInfo />
      <FooterNavi />
    </div>
    <div>
      <FooterFlat />
      <FooterCopy />
    </div>
  </footer>
);

export default Footer;
