import { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { LogoText } from 'orm-react/components/Logo';

import './styles.scss';

const HeaderBrand: FC<{
  title: string;
}> = ({ title }) => (
  <Link className="header__brand" to="/" title={title}>
    <LogoText />
  </Link>
);

HeaderBrand.propTypes = {
  title: PropTypes.string.isRequired
};

export default HeaderBrand;
