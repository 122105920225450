import { FC } from 'react';
import classNames from 'orm-react/helper/class-names';

import './styles.scss';

const NaviSandwich: FC<{
  isActive: boolean;
  toggleNavi: () => void;
}> = ({ isActive, toggleNavi }) => (
  <button
    type="button"
    className={classNames('sandwich', isActive && 'sandwich--close')}
    onClick={toggleNavi}
  >
    <i />
  </button>
);

export default NaviSandwich;
