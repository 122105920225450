import {
  useRef,
  useCallback,
  useState,
  FC,
  ChangeEvent,
  KeyboardEvent
} from 'react';
import PropTypes from 'prop-types';
import { useField, useForm } from 'react-final-form';

import {
  FieldSelectOption,
  FieldSelectOptionList
} from 'orm-react/components/Field/Select/Options';

import classNames from 'orm-react/helper/class-names';
import FormField, {
  FieldError,
  FieldLabel,
  FieldArrow
} from 'orm-react/components/Field';

import FieldSelectMultiDropDown from './DropDown';
import FieldSelectMultiSelected from './Selected';

import { FieldSelectOptions } from 'orm-react/components/Field/Select';
import { EVENT_KEY } from 'orm-react/helper/event';

import './styles.scss';

const FieldSelectMulti: FC<{
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  options: FieldSelectOptionList;
  className?: string;
}> = ({
  name,
  label,
  placeholder,
  required = false,
  disabled = false,
  readOnly,
  className,
  options
}) => {
  const { input } = useField(name);
  const [show, setShowDropDown] = useState(false);
  const [filter, setFilter] = useState('');
  const [next, setNext] = useState<number | null>(null);
  const [enter, setEnter] = useState(false);
  const dom = useRef<HTMLInputElement>(null);
  const select = useRef<HTMLSelectElement>(null);
  const form = useForm();
  const filteredList = options
    .filter(({ value }) => !input.value.includes(value))
    .filter(({ name }) =>
      filter ? name.toLowerCase().includes(filter.toLowerCase()) : true
    );
  const selectedList = input.value
    .map((key: string) => options.find(({ value }) => value === key))
    .filter(Boolean);

  const onBlur = () => {
    setFilter('');
    setShowDropDown(false);
    setNext(null);
    setEnter(false);
  };

  const onFocus = () => {
    setShowDropDown(true);
  };
  const onSelect = (value: string | number) => {
    const selected = input.value.filter((key: string) => key !== value);

    selected.push(value);
    input.onChange(selected);
    form.mutators.changed(input.name);

    setEnter(false);
    setNext(null);
  };

  const onUnSelect = (value: string | number) => {
    const selected = input.value.filter((key: string) => key !== value);

    input.onChange(selected);
    form.mutators.changed(input.name);
  };

  const onFilter = (evt: ChangeEvent<HTMLInputElement>) => {
    setFilter(evt.target.value);
    setShowDropDown(true);
  };

  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLInputElement>) => {
      switch (evt.code) {
        case EVENT_KEY.KEY_UP: {
          evt.preventDefault();
          const nstep = next === null ? 0 : next - 1;
          setNext(nstep < 0 ? filteredList.length - 1 : nstep);
          setShowDropDown(true);
          break;
        }
        case EVENT_KEY.KEY_DOWN:
          evt.preventDefault();
          setNext(next === null ? 0 : next + 1);
          setShowDropDown(true);
          break;
        case EVENT_KEY.KEY_ENTER:
          evt.preventDefault();
          setEnter(true);
          break;
        case EVENT_KEY.KEY_ESC:
          evt.preventDefault();
          setFilter('');
          setShowDropDown(false);
          setNext(null);
          break;
      }
    },
    [next]
  );

  return (
    <FormField
      required={required}
      value={filter}
      className={classNames('form__select2', className)}
    >
      <FieldError name={name} />
      <input
        ref={dom}
        id={name}
        className="field__input"
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onInput={onFilter}
        autoComplete="off"
        value={filter}
      />
      <FieldLabel name={name}>{label}</FieldLabel>
      <FieldSelectMultiDropDown
        list={filteredList}
        next={next}
        show={show}
        enter={enter}
        onSelect={onSelect}
      />
      <FieldSelectMultiSelected list={selectedList} onUnSelect={onUnSelect} />
      <select {...input} ref={select} name={name} multiple>
        <FieldSelectOptions options={options} />
      </select>
      <FieldArrow />
    </FormField>
  );
};

FieldSelectMulti.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf<FieldSelectOption>(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default FieldSelectMulti;
