import { useEffect, useRef } from 'react';

const useElasticTextarea = ({
  value,
  elastic
}: {
  value: any;
  elastic: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const timeout = useRef<number>(0);

  const resizeStage = () => {
    const { current: inputDom } = inputRef;

    if (inputDom && elastic && inputDom.scrollHeight && !timeout.current) {
      timeout.current = requestAnimationFrame(() => {
        const { style } = inputDom;

        if (!value) {
          style.height = '2rem';
        } else {
          style.height = `${inputDom.scrollHeight / 2}px`;
          style.height = `${inputDom.scrollHeight}px`;
        }

        timeout.current = 0;
      });
    }
  };

  useEffect(() => {
    const { current: inputDom } = inputRef;

    if (inputDom) {
      resizeStage();

      return () => {
        cancelAnimationFrame(timeout.current);
        timeout.current = 0;
      };
    }

    return;
  }, [value, elastic, inputRef.current]);

  return inputRef;
};

export default useElasticTextarea;
