import { FunctionComponent, FocusEvent, useRef } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';

import styles from './styles.module.scss';

const FieldFile: FunctionComponent<{
  name: string;
  accept: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}> = ({ name, accept, label = '', disabled = false, required = false }) => {
  const { input } = useField(name);
  const form = useForm();
  const file: any = useRef();
  const selected = input.value ? true : null;
  const fileName =
    file.current && file.current.files.length > 0
      ? file.current.files[0].name
      : undefined;

  const selectFile = () => {
    if (file.current) file.current.click();
  };
  const resetFile = () => {
    input.onChange(null);
    form.mutators.changed(input.name);
    if (file.current) file.current.value = '';
  };

  return (
    <FormField value={selected} required={required}>
      <FieldError name={name} />
      <Field name={name}>
        {({ input: { value, onChange, onBlur, ...rest } }) => (
          <input
            {...rest}
            id={name}
            ref={file}
            type="file"
            onChange={(event: FocusEvent<HTMLInputElement>) => {
              onChange(event.target.files && event.target.files[0]);
              // @ts-ignore
              onBlur(event.target);
            }}
            className={styles.field__file}
            accept={accept}
            required={required}
            disabled={disabled}
          />
        )}
      </Field>
      <FieldLabel name={name} onClick={selectFile}>
        {label}
      </FieldLabel>
      {selected ? (
        <button tabIndex={-1} type="button" onClick={resetFile}>
          <FontAwesomeIcon icon={['fas', 'times']} />
        </button>
      ) : (
        <button tabIndex={-1} type="button" onClick={selectFile}>
          <FontAwesomeIcon icon={['fas', 'file']} />
        </button>
      )}
      <div className={styles.field__file__name} title={fileName}>
        {fileName}
      </div>
    </FormField>
  );
};

FieldFile.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default FieldFile;
