import {
  ChangeEvent,
  useRef,
  useState,
  useEffect,
  useContext,
  ReactElement
} from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';
import { FieldSwitchSingle } from 'orm-react/components/Field';
import useOverlay from 'orm-react/hooks/overlay';
import { ContextLayer } from 'orm-react/contexts/Layer';

import './styles.scss';

const LIFETIME = 60 * 60 * 1000 * 24 * 365;

const LayerPrivacy = ({ renderLink }: { renderLink: () => ReactElement }) => {
  const notice = useRef<HTMLDivElement>(null);
  const placeholder = useRef<HTMLDivElement>(null);
  const didMountRef = useRef<boolean>(false);
  const { current } = useContext(ContextLayer);
  const [isClosed, setClosed] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [isTracking, setTracking] = useState(false);
  const [isMarketing, setMarketing] = useState(false);

  function toggleTracking(e: ChangeEvent<HTMLInputElement>) {
    setTracking(e.target.checked);
  }

  function toggleMarketing(e: ChangeEvent<HTMLInputElement>) {
    setMarketing(e.target.checked);
  }

  function setCookie(type: string, check: boolean) {
    const nextYear = new Date(new Date().getTime() + LIFETIME).toUTCString();
    document.cookie = `privacy__${type}=${check}; expires=${nextYear};`;
  }

  const acceptTracking = (all: boolean) => {
    if (all) {
      setTracking(true);
      setMarketing(true);
      setCookie('tracking', true);
      setCookie('marketing', true);
    } else {
      setCookie('tracking', isTracking);
      setCookie('marketing', isMarketing);
    }

    if ((all || isTracking) && window.trackingInit) {
      window.trackingInit();
    }

    setClosed(true);
    setShowSettings(false);
    setCookie('closed', true);
  };

  useEffect(() => {
    setClosed(document.cookie.indexOf('privacy__closed=true') > -1);
    setTracking(document.cookie.indexOf('privacy__tracking=true') > -1);
    setMarketing(document.cookie.indexOf('privacy__marketing=true') > -1);
  }, []);

  useEffect(() => {
    if (current === 'privacy') {
      setClosed(false);
    }
  }, [current]);

  useEffect(() => {
    if (didMountRef.current) {
      const { current: domNotice } = notice;
      const { current: domPlaceholder } = placeholder;

      if (domPlaceholder && domNotice) {
        domPlaceholder.style.height = isClosed
          ? '0'
          : `${domNotice.offsetHeight}px`;
      }
    } else didMountRef.current = true;
  }, [isClosed]);

  useOverlay(showSettings);

  return (
    <div className="tracking">
      <div className="tracking__placeholder" ref={placeholder} />
      <div
        className={classNames(
          'tracking__notice',
          showSettings && 'open',
          !isClosed && 'active'
        )}
        ref={notice}
      >
        <div className="container">
          <div
            className={classNames('tracking__infos', showSettings && 'active')}
          >
            <span className="link">
              <span
                dangerouslySetInnerHTML={{ __html: __('text.privacy.cookie') }}
              />
              {renderLink()}
              {'.'}
            </span>
          </div>
          <div
            className={classNames(
              'tracking__details link',
              showSettings && 'active'
            )}
          >
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: __('text.privacy.cookie.info')
                }}
              />
              {renderLink()}
              {'.'}
            </p>
            <div>
              <label>
                <span>{__('label.standard.cookie')}</span>
                <span>{__('label.always.active')}</span>
              </label>
              <div
                dangerouslySetInnerHTML={{
                  __html: __('text.standard.cookie.details')
                }}
              />
            </div>
            <div>
              <FieldSwitchSingle
                name={'tracking'}
                value={isTracking ? '1' : '0'}
                checked={isTracking}
                onChange={toggleTracking}
                label={__('label.analytics.cookie')}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: __('text.analytics.cookie.details')
                }}
              />
            </div>
            <div>
              <FieldSwitchSingle
                name={'marketing'}
                value={isMarketing ? '1' : '0'}
                checked={isMarketing}
                onChange={toggleMarketing}
                label={__('label.marketing.cookie')}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: __('text.marketing.cookie.details')
                }}
              />
            </div>
          </div>
          <div className="tracking__action">
            {showSettings ? (
              <>
                <button
                  type="button"
                  className="tracking__settings"
                  onClick={() => acceptTracking(false)}
                >
                  {__('btn.save')}
                </button>
                <button
                  type="button"
                  className="tracking__accept"
                  onClick={() => acceptTracking(true)}
                >
                  {__('btn.accept.all')}
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="tracking__settings"
                  onClick={() => setShowSettings(!showSettings)}
                >
                  {__('btn.settings')}
                </button>
                <button
                  type="button"
                  className="tracking__accept"
                  onClick={() => acceptTracking(true)}
                >
                  {__('btn.accept.all')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LayerPrivacy.propTypes /* remove-proptypes */ = {
  renderLink: PropTypes.func.isRequired
};

export default LayerPrivacy;
