import { useEffect } from 'react';

import './styles.scss';

export default function useOverlay(toggle: boolean) {
  useEffect(() => {
    const { classList } = document.documentElement;

    if (toggle) {
      classList.add('overlay');
    } else {
      classList.remove('overlay');
    }

    return () => classList.remove('overlay');
  }, [toggle]);
}
