import { ReactElement, FunctionComponent, ReactNode } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const Field: FunctionComponent<{
  children: ReactNode;
  value?: string | number | boolean | null;
  required?: boolean;
  className?: string;
}> = ({
  value,
  children,
  required = false,
  className = null
}): ReactElement => (
  <div
    className={classNames(
      styles.field,
      className,
      required && styles.field__required,
      value !== null &&
        value !== '' &&
        value !== false &&
        value !== undefined &&
        styles.field__active
    )}
  >
    {children}
  </div>
);

Field.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  required: PropTypes.bool,
  className: PropTypes.string
};

export default Field;
