import { FocusEvent, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useForm, useField, Field } from 'react-final-form';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';
import { padZero } from 'orm-react/helper/string';

import styles from 'orm-react/components/Field/Select/Select/styles.module.scss';

const testHour = /(\d{1,2})\d{2}$/;
const testMinute = /\d{0,2}(\d{2})$/;

const FieldTime: FunctionComponent<{
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  minuteStep?: number;
}> = ({
  name,
  label = '',
  disabled = false,
  required = false,
  minuteStep = 15
}) => {
  const form = useForm();
  const { input } = useField(name);
  const { value } = input;
  const matchHour = testHour.exec(String(value));
  const matchMinute = testMinute.exec(String(value));

  const onChangeHour = (e: FocusEvent<HTMLSelectElement>) => {
    const hour = parseInt(e.target.value, 10);

    if (!matchMinute) {
      input.onChange(Number(`${hour}00`));
      form.mutators.changed(input.name);
    } else if (matchMinute && matchMinute[1]) {
      input.onChange(Number(`${hour}${padZero(matchMinute[1])}`));
      form.mutators.changed(input.name);
    }
  };

  const onChangeMinute = (e: FocusEvent<HTMLSelectElement>) => {
    const min = parseInt(e.target.value, 10);

    if (!matchHour) {
      input.onChange(Number(`00${padZero(min)}`));
      form.mutators.changed(input.name);
    } else if (matchHour && matchHour[1]) {
      input.onChange(Number(`${matchHour[1]}${padZero(min)}`));
      form.mutators.changed(input.name);
    }
  };

  const hours = Array.from(Array(24).keys()).map((i) => padZero(i));
  const minutes = Array.from(Array(60 / minuteStep).keys()).map((i) =>
    padZero(i * minuteStep)
  );

  return (
    <FormField required={required} value={value}>
      <FieldError name={name} />
      <select
        id={name}
        className={styles.field__select}
        required={required}
        disabled={disabled}
        onChange={onChangeHour}
        value={matchHour ? padZero(matchHour[1]) : undefined}
        title={__('label.hour')}
      >
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <span className="time-spacer">:</span>
      <select
        className={styles.field__select}
        required={required}
        disabled={disabled}
        onChange={onChangeMinute}
        value={matchMinute ? padZero(matchMinute[1]) : undefined}
        title={__('label.minute')}
      >
        {minutes.map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </select>
      <FieldLabel name={name}>{label}</FieldLabel>
      <Field name={name} component="input" type="hidden" />
    </FormField>
  );
};

FieldTime.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  minuteStep: PropTypes.number
};

export default FieldTime;
