import { ReactElement, FunctionComponent } from 'react';
import { Field, useField } from 'react-final-form';
import PropTypes from 'prop-types';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';

import styles from './styles.module.scss';

const FieldCheckbox: FunctionComponent<{
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
}> = ({
  name,
  label = '',
  disabled = false,
  required = false,
  readOnly = false
}): ReactElement => {
  const { input } = useField(name);
  const htmlFor = `${input.name}_${input.value}`;
  const onClick = () => {
    input.onChange(!input.value);
  };

  return (
    <FormField
      className={styles.field__checkbox}
      required={required}
      value={input.value}
    >
      <FieldError name={name} />
      <Field
        id={htmlFor}
        name={name}
        type="checkbox"
        component="input"
        required={required}
        readOnly={readOnly}
        disabled={disabled}
      />
      <i onClick={onClick} />
      <FieldLabel name={htmlFor}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </FieldLabel>
    </FormField>
  );
};

FieldCheckbox.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string
};

export default FieldCheckbox;
