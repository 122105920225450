import styles from '../Input/styles.module.scss';

import { FunctionComponent, ReactNode } from 'react';
import { Field, useField } from 'react-final-form';
import PropTypes from 'prop-types';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';
import useElasticTextarea from 'orm-react/hooks/elastic-textarea';
import classNames from 'orm-react/helper/class-names';

const FieldText: FunctionComponent<{
  name: string;
  label?: string;
  placeholder?: string;
  elastic?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  info?: ReactNode;
  rows?: number;
  cols?: number;
  format?: (value: string, name?: string) => string;
}> = ({
  name,
  elastic = false,
  required,
  disabled,
  readOnly,
  label,
  placeholder,
  info,
  rows,
  cols,
  format
}) => {
  const { input } = useField(name);
  const inputRef = useElasticTextarea({ value: input.value, elastic });

  return (
    <FormField required={required} value={input.value}>
      <FieldError name={name} />
      <Field
        ref={inputRef}
        id={name}
        name={name}
        className={classNames(styles.field__input, elastic && 'elastic')}
        component="textarea"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        rows={rows}
        cols={cols}
        format={format}
        formatOnBlur
      />
      <FieldLabel name={name}>{label}</FieldLabel>
      {info}
    </FormField>
  );
};

FieldText.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  elastic: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  info: PropTypes.node,
  format: PropTypes.func
};

export default FieldText;
