import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'orm-react/helper/class-names';

import './styles.scss';

const HeaderManager: FC<{
  className?: string | boolean;
}> = ({ className }) => (
  <a
    className={classNames('header__manager', className)}
    target="_blank"
    href="https://manager.cantinorant.com"
  >
    <FontAwesomeIcon icon={['fas', 'rocket']} />
    <span>Manager</span>
  </a>
);

export default HeaderManager;
