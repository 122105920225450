import styles from './styles.module.scss';

import { FunctionComponent, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'react-final-form';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';
import Modal from 'orm-react/components/Modal';

import inputStyles from 'orm-react/components/Field/Input/styles.module.scss';
import { getContrastYIQ, HEX_MATCH, RGB_MATCH } from 'orm-react/helper/color';

const testColor = (value: string) =>
  value &&
  (HEX_MATCH.test(value) || RGB_MATCH.test(value)
    ? undefined
    : __('alert.error.color.has.wrong.value'));

const formatColor = (value: string) =>
  value
    ? (HEX_MATCH.test(value) && !value.includes('#') ? `#${value}` : value)
        .toLowerCase()
        .trim()
    : value;

/**
 * @tailwind block-picker
 */
const FieldColor: FunctionComponent<{
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  allowNull?: boolean;
}> = ({
  name,
  label = '',
  placeholder,
  disabled = false,
  required = false,
  readOnly = false,
  allowNull
}) => {
  const { input } = useField(name);
  const [show, setShow] = useState(false);

  const changeColor = (color: ColorResult) => {
    input.onChange(formatColor(color.hex));
  };

  return (
    <FormField required={required} value={input.value}>
      <FieldError name={name} />
      <Field
        id={name}
        name={name}
        title={label || placeholder}
        type="text"
        component="input"
        className={inputStyles.field__input}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        validate={testColor}
        allowNull={allowNull}
        format={formatColor}
        formatOnBlur
      />
      <FieldLabel name={name}>{label}</FieldLabel>
      <button
        className={styles.field__color}
        type="button"
        style={{
          backgroundColor: input.value,
          color: getContrastYIQ(input.value, '#bfbfbf')
        }}
        onClick={() => setShow(!show)}
      >
        <FontAwesomeIcon icon={['fas', 'palette']} />
      </button>
      {show && (
        <Modal size="auto" show={show} closeable onClose={() => setShow(false)}>
          <ChromePicker color={input.value} onChangeComplete={changeColor} />
        </Modal>
      )}
    </FormField>
  );
};

export default FieldColor;
