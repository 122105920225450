import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useContext, useEffect } from 'react';

import { ContextToaster } from 'orm-react/contexts/Toaster';

const TerrorMessage = ({
  error,
  touched
}: {
  error: string | string[];
  touched: boolean;
}) => {
  const { showToaster } = useContext(ContextToaster);

  useEffect(() => {
    if (error && touched) {
      ([] as string[])
        .concat(error)
        .filter(Boolean)
        .forEach((err) => {
          showToaster('error', err);
        });
    }
  }, [error, touched]);

  return null;
};

TerrorMessage.propTypes /* remove-proptypes */ = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool
  ]),
  touched: PropTypes.bool
};

TerrorMessage.defaultProps = {
  error: false,
  touched: false
};

const FieldTerror = ({ name }: { name: string }) => (
  <Field
    name={name}
    subscribe={{
      touched: true,
      error: true,
      invalid: true,
      submit: true
    }}
    render={({ meta: { touched, error, submitError } }) => (
      <TerrorMessage error={error || submitError} touched={touched} />
    )}
  />
);

FieldTerror.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired
};

export default FieldTerror;
