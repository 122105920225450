import { FC, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const HeaderSubNavi: FC<{
  list: Array<{
    id: string;
    label: string;
    url: string;
  }>;
  siteUrl: string;
}> = ({ list, siteUrl }) => {
  const toggle = useRef<HTMLElement>(null);

  const toggleMenu = (e: MouseEvent) => {
    e.stopPropagation();
    const { current } = toggle;
    if (current && current.previousSibling) {
      const { previousSibling: aDom } = current;
      (aDom as HTMLElement).classList.toggle('active');
    }
  };

  useEffect(() => {
    const { current } = toggle;

    if (current && current.parentNode) {
      const span = current.parentNode.querySelector('li > span');

      current.addEventListener('click', toggleMenu);
      if (span) {
        current.parentNode.addEventListener(
          'click',
          toggleMenu as EventListener
        );
      }

      return () => {
        current.removeEventListener('click', toggleMenu);
        if (current && current.parentNode && span) {
          current.parentNode.removeEventListener(
            'click',
            toggleMenu as EventListener
          );
        }
      };
    }
  }, []);

  return (
    <>
      <ul>
        {list.map((sub) => (
          <li key={sub.id}>
            <Link
              activeClassName="active"
              to={sub.url.replace(siteUrl, '')}
              dangerouslySetInnerHTML={{ __html: sub.label }}
            />
          </li>
        ))}
      </ul>
      <i ref={toggle}>
        {/* <svg /> */}
        <FontAwesomeIcon icon={['fas', 'chevron-down']} />
      </i>
    </>
  );
};

HeaderSubNavi.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  siteUrl: PropTypes.string.isRequired
};

export default HeaderSubNavi;
