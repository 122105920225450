import { FC } from 'react';

export interface FieldSelectOption {
  name: string;
  value: string | number;
  disabled?: boolean | undefined;
}

export type FieldSelectOptionList = Array<FieldSelectOption>;

export const selectOptions = (data: {
  [index: string]: string;
}): FieldSelectOptionList =>
  Object.keys(data).map((key) => ({
    value: key,
    name: data[key]
  }));

const FieldSelectOptions: FC<{
  options: FieldSelectOptionList;
}> = ({ options }) => (
  <>
    {options.map(
      (options) =>
        options && (
          <option
            key={options.value}
            value={options.value}
            disabled={options.disabled || undefined}
          >
            {options.name}
          </option>
        )
    )}
  </>
);

export default FieldSelectOptions;
