import styles from './styles.module.scss';

import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';

import FormField, {
  FieldArrow,
  FieldError,
  FieldLabel,
  FieldLoader
} from 'orm-react/components/Field';

const FieldSelect: FC<{
  name: string;
  children: ReactNode;
  className?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
}> = ({
  name,
  label,
  disabled = false,
  required = false,
  loading = false,
  className,
  children
}) => {
  const { input } = useField(name);

  return (
    <FormField required={required} value={input.value} className={className}>
      <FieldError name={name} />
      <Field
        id={name}
        name={name}
        title={label}
        component="select"
        className={styles.field__select}
        disabled={disabled}
        required={required}
      >
        <option style={{ display: 'none' }} key={label} value="" />
        {children}
      </Field>
      <FieldLabel name={name}>{label}</FieldLabel>
      <FieldLoader loading={loading} />
      <FieldArrow />
    </FormField>
  );
};

FieldSelect.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default FieldSelect;
