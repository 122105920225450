import { padZero } from 'orm-react/helper/string';
import { getUTCDate } from 'orm-lib/helper/date';

/*
Date.prototype.getWeek = function() {

  // Create a copy of this date object
  var target  = new Date(this.valueOf());

  // ISO week date weeks start on monday, so correct the day number
  var dayNr   = (this.getDay() + 6) % 7;

  // Set the target to the thursday of this week so the
  // target date is in the right year
  target.setDate(target.getDate() - dayNr + 3);

  // ISO 8601 states that week 1 is the week with january 4th in it
  var jan4    = new Date(target.getFullYear(), 0, 4);

  // Number of days between target date and january 4th
  var dayDiff = (target - jan4) / 86400000;

  if(new Date(target.getFullYear(), 0, 1).getDay() < 5) {
    // Calculate week number: Week 1 (january 4th) plus the
    // number of weeks between target date and january 4th
    return 1 + Math.ceil(dayDiff / 7);
  }
  else {  // jan 4th is on the next week (so next week is week 1)
    return Math.ceil(dayDiff / 7);
  }
};
 */

const IS_W3C = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/;

export const getWeekNumber = (date = new Date()): number => {
  // Copy date so don't modify original
  const current = getUTCDate(date);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  current.setUTCDate(current.getUTCDate() + 4 - (current.getUTCDay() || 7));
  // Get first day of year
  const yearStart = Number(
    new Date(Date.UTC(current.getUTCFullYear(), 0, 1, 0, 0, 0))
  );
  // Calculate full weeks to nearest Thursday
  return Math.ceil(((Number(current) - yearStart) / 86400000 + 1) / 7);
};

export const getDateRangeOfWeek = (
  week: number,
  year: number
): [Date, Date] => {
  const d = new Date(Date.UTC(year, 0, 1, 0, 0, 0));
  const monday = new Date(
    new Date(Date.UTC(year, 0, 1, 0, 0, 0)).setDate(d.getDate() - 6)
  );
  const w = monday.getTime() + 604800000 * week;
  const curr = new Date(w);

  const first =
    curr.getDay() === 6
      ? curr.getDate() + 2
      : curr.getDate() - (curr.getDay() - 1);
  const last = first + 6; // last day is the first day + 6

  const start = new Date(curr.setDate(first));
  const end = new Date(curr.setDate(last));

  return [getUTCDate(start), getUTCDate(end)];
};

const lang = (typeof navigator === 'object' && navigator.language) || 'de-DE';

type DateFormat =
  | 'short'
  | 'long'
  | 'weekday'
  | 'month'
  | 'time'
  | 'time_long'
  | 'w3c'
  | 'year';

const DATE_FORMAT: { [key in DateFormat]: Intl.DateTimeFormat } = {
  short: new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  }),
  long: new Intl.DateTimeFormat(lang, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  }),
  weekday: new Intl.DateTimeFormat(lang, {
    weekday: 'long',
    timeZone: 'UTC'
  }),
  time: new Intl.DateTimeFormat(lang, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  }),
  time_long: new Intl.DateTimeFormat(lang, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC'
  }),
  w3c: new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC'
  }),
  month: new Intl.DateTimeFormat(lang, {
    month: 'long',
    timeZone: 'UTC'
  }),
  year: new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    timeZone: 'UTC'
  })
};

/**
 * @param {Date|String} date
 *
 * @return {string}
 */
export const getW3CDate = (date: Date | string) => {
  const formatted =
    typeof date === 'string'
      ? IS_W3C.test(date)
        ? new Date(date)
        : new Date(`${date}.000Z`)
      : date;
  const timezone = new Date(
    formatted.getTime() + formatted.getTimezoneOffset() * 60000
  );

  return [
    timezone.getFullYear(),
    '-',
    padZero(timezone.getMonth() + 1),
    '-',
    padZero(timezone.getDate()),
    'T',
    padZero(timezone.getHours()),
    ':',
    padZero(timezone.getMinutes()),
    ':',
    padZero(timezone.getSeconds())
  ].join('');
};

/**
 *
 * @param date - W3C String
 * @param format
 */
export const formatDate = (date: Date | string, format: DateFormat) =>
  DATE_FORMAT[format].format(
    typeof date === 'string' ? new Date(`${getW3CDate(date)}.000Z`) : date
  );

export const getTimeInt = (now = new Date()) =>
  Number(`${padZero(now.getHours())}${padZero(now.getMinutes())}`);

export const isToday = (dist: Date, src: Date) =>
  formatDate(dist, 'short') === formatDate(src, 'short');

/**
 * extend to date 1w / 5d / 2m
 *
 * @param {String} nextDate
 * @param {Date}  [newDate]
 * @returns {Date}
 */
export const extendDate = ({
  nextDate,
  newDate = new Date()
}: {
  nextDate?: string;
  newDate?: Date;
}) => {
  const key = String(nextDate).match(/(\d+)(\w+)/);

  if (key) {
    const value = parseInt(key[1]);
    switch (key[2]) {
      case 'd':
        newDate.setDate(newDate.getDate() + value);
        break;
      case 'w':
        newDate.setDate(newDate.getDate() + value * 7);
        break;
      case 'm':
        newDate.setMonth(newDate.getMonth() + value);
        break;
      case 's':
        newDate.setSeconds(newDate.getSeconds() + value);
        break;
      default:
    }
  }

  return newDate;
};

/**
 * contract to date 1w / 5d / 2m
 *
 * m = Month
 * d = Day
 * w = Week
 * s = Seconds
 * min = Minute
 * h = Hour
 *
 * @param {String} nextDate
 * @param {Date}  [newDate]
 * @returns {Date}
 */
export const contractDate = ({
  prevDate,
  newDate = new Date()
}: {
  prevDate: string;
  newDate?: Date;
}) => {
  const key = String(prevDate).match(/(\d+)(\w+)/);

  if (key) {
    const value = parseInt(key[1]);
    switch (key[2]) {
      case 'd':
        newDate.setDate(newDate.getDate() - value);
        break;
      case 'w':
        newDate.setDate(newDate.getDate() - value * 7);
        break;
      case 'm':
        newDate.setMonth(newDate.getMonth() - value);
        break;
      case 'min':
        newDate.setMinutes(newDate.getMinutes() - value);
        break;
      case 'h':
        newDate.setHours(newDate.getHours() - value);
        break;
      default:
    }
  }

  return newDate;
};

export function getMinDate(date: string | Date | undefined) {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date === 'string') {
    return extendDate({ newDate: new Date(date) });
  }

  return undefined;
}

export const getStartOfDay = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  );

export const getEndOfDay = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
  );

export const getTodayFilter = () => ({
  from: getUTCDate(new Date()),
  till: getUTCDate(new Date())
});
