import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO: FC<{
  title: string;
  description?: string;
  lang?: string;
  keywords?: Array<string>;
}> = ({ title, lang = 'de', keywords = [], description = '' }) => {
  const {
    site: { siteMetadata },
    wpContent: { generalSettings },
    metaImage: { publicURL: image }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        metaImage: file(relativePath: { eq: "logo/cantinorant_q.png" }) {
          publicURL
        }
        wpContent {
          generalSettings {
            title
            description
            url
          }
        }
      }
    `
  );

  const metaTitle = title || generalSettings.title || siteMetadata.title;
  const metaDescription =
    description || generalSettings.description || siteMetadata.description;

  const bodyClass = [
    'elementor-kit-6',
    'elementor-template-full-width',
    'elementor-page'
  ].join(' ');

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`%s | ${generalSettings.title || siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: metaTitle
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: `${siteMetadata.siteUrl}${image}`
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: metaTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          property: 'twitter:image',
          content: `${siteMetadata.siteUrl}${image}`
        }
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(',')
            }
          : []
      )}
    >
      <body className={bodyClass} />
    </Helmet>
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string.isRequired),
  title: PropTypes.string.isRequired
};

export default SEO;
