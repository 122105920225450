import { ChangeEvent, FC } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

import styles from './styles.module.scss';

const FieldSwitchSingle: FC<{
  name: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  checked?: boolean;
  value?: string | number;
}> = ({
  name,
  onChange,
  label,
  className,
  readOnly = false,
  disabled = false,
  value = 0,
  checked
}) => (
  <div className={classNames(styles.field__switch, className)}>
    <input
      id={name}
      name={name}
      type="checkbox"
      onChange={onChange}
      className={className}
      disabled={disabled}
      checked={checked}
      readOnly={readOnly}
      defaultValue={value}
    />
    <label htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <i />
    </label>
  </div>
);

FieldSwitchSingle.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string]),
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default FieldSwitchSingle;
