import styles from './styles.module.scss';
import fieldStyles from '../Field/styles.module.scss';

import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';
import { FieldError } from 'orm-react/components/Field';

interface FieldRadioProps {
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
}

const FieldRadio = ({
  name,
  label,
  value,
  disabled,
  required
}: FieldRadioProps) => {
  const id = `${name}_${value}`;

  return (
    <label
      htmlFor={id}
      className={classNames(
        styles.field__radio,
        required && fieldStyles.field__required
      )}
    >
      <FieldError name={name} />
      <Field
        id={id}
        name={name}
        type="radio"
        title={label}
        component="input"
        required={required}
        disabled={disabled}
        value={value}
      />
      <i />
      <span>{label}</span>
    </label>
  );
};

FieldRadio.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default FieldRadio;
