import styles from './styles.module.scss';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const ErrorMessage = ({
  name,
  error,
  touched
}: {
  name: string;
  error: string | string[];
  touched: boolean;
}) =>
  touched && error ? (
    <ul className={styles.field__error} data-error-field={name}>
      {([] as string[])
        .concat(error)
        .filter(Boolean)
        .map((err) => (
          <li key={err}>{err}</li>
        ))}
    </ul>
  ) : null;

ErrorMessage.propTypes /* remove-proptypes */ = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool
  ]),
  input: PropTypes.shape({}).isRequired,
  touched: PropTypes.bool
};

ErrorMessage.defaultProps = {
  error: false,
  touched: false
};

const FieldError = ({ name }: { name: string }) => (
  <Field
    name={name}
    subscribe={{
      touched: true,
      error: true,
      invalid: true
    }}
    render={({ input, meta: { touched, error, submitError } }) => (
      <ErrorMessage
        name={name}
        error={error || submitError}
        touched={touched}
        input={input}
      />
    )}
  />
);

FieldError.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired
};

export default FieldError;
