import React, { MouseEvent, FC, useEffect, useState } from 'react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

import classNames from 'orm-react/helper/class-names';

import SubNavi from '../SubNavi';

import { useHeader } from '../Header';

import './styles.scss';

type NaviElement = {
  url: string;
  id: string;
  label: string;
  childItems: {
    nodes: Array<NaviElement>;
  };
};

const EmptyNavi = ({ url, label }: { url: string; label: string }) => (
  <Location>
    {({ location: { pathname } }) => (
      <span
        className={classNames(
          pathname.indexOf(url.replace('@', '/')) === 0 && 'active selected'
        )}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    )}
  </Location>
);

const SpaNavi = ({
  url,
  label,
  currentPath
}: {
  url: string;
  label: string;
  currentPath: string;
}) => {
  const isSamePath = currentPath === '/';
  const [isActive, setActive] = useState(false);
  const { toggleNavi } = useHeader();

  const observer =
    typeof window !== 'undefined' &&
    'IntersectionObserver' in window &&
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setActive(entry.isIntersecting);
      });
    });

  const jumpTo = (event: MouseEvent<HTMLAnchorElement>) => {
    if (currentPath === '/') {
      event.preventDefault();
      const item = document.querySelector(url);
      if (item)
        item.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      toggleNavi();
    }
  };

  useEffect(() => {
    const item = document.querySelector(url);

    if (item && observer) observer.observe(item);

    return () => {
      if (item && observer) observer.unobserve(item);
    };
  }, []);

  return !isSamePath ? (
    <Link
      onClick={jumpTo}
      to={`/${url}`}
      activeClassName={classNames(isActive && 'active selected')}
      partiallyActive
    >
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </Link>
  ) : (
    <button
      // @ts-ignore
      onClick={jumpTo}
      className={classNames(isActive && 'active selected')}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
};

const NaviLink = ({ url, label }: { url: string; label: string }) => {
  // if navigation point without page
  if (url.indexOf('@') === 0) {
    return <EmptyNavi url={url} label={label} />;
  }

  // if navigation point for spa page
  if (url.indexOf('#') === 0) {
    return (
      <Location>
        {({ location: { pathname } }) => (
          <SpaNavi url={url} label={label} currentPath={pathname} />
        )}
      </Location>
    );
  }

  return (
    <Link to={url} activeClassName="active selected" partiallyActive>
      <span dangerouslySetInnerHTML={{ __html: label }} />
    </Link>
  );
};

const HeaderMainNavi: FC<{
  wpContent: {
    menuItems: {
      nodes: Array<NaviElement>;
    };
    generalSettings: { url: string };
  };
}> = ({
  wpContent: {
    menuItems: { nodes },
    generalSettings: { url: siteUrl }
  }
}) => {
  if (!nodes.length) {
    return null;
  }

  return (
    <>
      {nodes.map(({ url, id, label, childItems }) => {
        const hasChilds = childItems.nodes.length > 0;

        return (
          <li key={id} className={classNames(hasChilds && 'submenu')}>
            <NaviLink url={url.replace(siteUrl, '')} label={label} />
            {hasChilds && <SubNavi list={childItems.nodes} siteUrl={siteUrl} />}
          </li>
        );
      })}
    </>
  );
};

export default HeaderMainNavi;
