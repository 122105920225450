import './styles.scss';

import { Children, cloneElement, ReactElement } from 'react';

const LinkWrapper = ({ children }: { children: ReactElement }) => {
  const StyledChildren = (): ReactElement => (
    <>
      {Children.map(children, (child) =>
        cloneElement(child, {
          className: `${child.props.className || ''} link`.trim()
        })
      )}
    </>
  );

  return <StyledChildren />;
};

export default LinkWrapper;
