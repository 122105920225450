import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';

const LinkExternal: FC<{
  href: string;
  children: ReactNode;
}> = ({ href, children, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" href={href} {...props}>
    {children}
  </a>
);

LinkExternal.propTypes /* remove-proptypes */ = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default LinkExternal;
