import { StaticQuery, Link, graphql } from 'gatsby';

const FooterNavi = () => (
  <StaticQuery
    query={graphql`
      fragment menuItem on WPGraphQL_MenuItem {
        id
        url
        label
      }

      query NaviFooter {
        wpContent {
          generalSettings {
            url
          }
          footerCol1: menuItems(where: { location: FOOTER_COL_1 }) {
            nodes {
              ...menuItem
            }
          }
          footerCol2: menuItems(where: { location: FOOTER_COL_2 }) {
            nodes {
              ...menuItem
            }
          }
          footerCol3: menuItems(where: { location: FOOTER_COL_3 }) {
            nodes {
              ...menuItem
            }
          }
          footerCol4: menuItems(where: { location: FOOTER_COL_4 }) {
            nodes {
              ...menuItem
            }
          }
        }
      }
    `}
    render={({
      wpContent: {
        generalSettings: { url: siteUrl },
        footerCol1,
        footerCol2,
        footerCol3,
        footerCol4
      }
    }) => (
      <div className="footer__navi">
        {[footerCol1, footerCol2, footerCol3, footerCol4].map(
          ({ nodes }, index) =>
            nodes.length > 0 && (
              <div key={index}>
                <ul>
                  {nodes.map(
                    ({ label, url }: { label: string; url: string }) => (
                      <li key={url}>
                        {url === '#' ? (
                          <div>{label}</div>
                        ) : (
                          <Link to={url.replace(siteUrl, '')}>{label}</Link>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )
        )}
      </div>
    )}
  />
);

export default FooterNavi;
