import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

import './styles.scss';

const FooterCopy = () => (
  <div className="footer__copy">
    <div>
      <StaticQuery
        query={graphql`
          query FooterCopyQuery {
            wpContent {
              generalSettings {
                url
                title
                description
              }
            }
          }
        `}
        render={({ wpContent }) => (
          <>
            <Link to="/" title={wpContent.generalSettings.description}>
              <span>&copy;{new Date().getFullYear()}</span>
              <span>{wpContent.generalSettings.title}</span>
            </Link>
            <span>{__('label.all.rights.reserved')}</span>
          </>
        )}
      />
    </div>
  </div>
);

export default FooterCopy;
