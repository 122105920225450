import { MouseEvent, ReactNode, TouchEvent, useEffect, useRef } from 'react';

import { CompletingEntry } from '../Completing';

const FieldCompletingData = <Type extends Record<string, any>>({
  data,
  selectValue,
  selected,
  hoverMe,
  inputValue,
  render
}: {
  selected: number;
  hoverMe: (selected: number) => void;
  inputValue: string;
  data: CompletingEntry<Type>[];
  selectValue: (value: CompletingEntry<Type>) => void;
  render: ({
    query,
    entry
  }: {
    entry: CompletingEntry<Type>;
    query: string;
  }) => ReactNode;
}) => {
  const container = useRef<HTMLUListElement>(null);

  const scrollElement = () => {
    const { current } = container;

    if (current) {
      const active = current.querySelector<HTMLLIElement>('.active');
      if (active && selected) {
        current.scrollTop = active.offsetTop - current.clientHeight / 3;
      } else {
        current.scrollTop = 0;
      }
    }
  };

  const onClose =
    (entry: CompletingEntry<Type>) =>
    (e: MouseEvent<HTMLLIElement> | TouchEvent<HTMLLIElement>) => {
      e.preventDefault();
      selectValue(entry);
    };

  useEffect(() => {
    scrollElement();
  }, []);

  if (!data || !data.length) {
    return null;
  }

  return (
    <ul ref={container}>
      {data.map((entry, key: number) => (
        <li
          key={entry.id}
          className={selected === key ? 'active' : undefined}
          title={entry.value}
          onMouseDown={onClose(entry)}
          onTouchEnd={onClose(entry)}
          onMouseEnter={() => hoverMe(key)}
        >
          {render({ query: inputValue, entry })}
        </li>
      ))}
    </ul>
  );
};

export default FieldCompletingData;
