import { useContext } from 'react';

import { ContextToaster } from 'orm-react/contexts/Toaster';
import { Conform } from 'orm-react/helper/conforma';

export function extractErrors({
  graphQLErrors = []
}: Error & { graphQLErrors?: [] }) {
  const conformaErrors: { [index: string]: string } = graphQLErrors.reduce(
    (list, error: Error & { field: string }) => {
      if (error.name === 'ErrorValidation') {
        return {
          ...list,
          [error.field]: Conform.msg(error, error.message)
        };
      }
      return list;
    },
    {}
  );

  return Object.keys(conformaErrors).reduce(
    (cErrors: { [index: string]: string | object }, key) => {
      const keys = key.split('.');
      let tmp = cErrors;

      keys.forEach((subkey, index) => {
        if (!tmp[subkey]) {
          tmp[subkey] = index + 1 === keys.length ? conformaErrors[key] : {};
        }
        // @ts-ignore
        tmp = tmp[subkey];
      });

      return cErrors;
    },
    {}
  );
}

export default function useTerror(): (error: Error) => Promise<object> {
  const { showToaster } = useContext(ContextToaster);

  return (error: Error & { graphQLErrors?: [] }) => {
    showToaster('error', error.message);
    const validation = extractErrors(error);
    console.error(`Internal Server Error:`, {
      error,
      message: error.message,
      graphQLErrors: error.graphQLErrors,
      validation
    });

    return Promise.resolve(validation);
  };
}
