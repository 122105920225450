import styles from './styles.module.scss';

import { ReactNode, FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'orm-react/helper/class-names';

type ButtonType = 'button' | 'submit';
type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonState =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'neutral'
  | 'primary';
type ButtonVariant = 'text' | 'solid' | 'full';

export type ButtonProps = {
  children: ReactNode;
  type: ButtonType;
  state?: ButtonState;
  size?: ButtonSize;
  variant?: ButtonVariant;
  title?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Button: FC<ButtonProps> = ({
  children,
  type,
  size = 'sm',
  variant = 'full',
  title,
  disabled,
  state,
  onClick,
  className
}) => (
  <button
    className={classNames(styles.button, state, size, variant, className)}
    type={type}
    onClick={onClick}
    title={title}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf<ButtonType>(['button', 'submit']).isRequired,
  state: PropTypes.oneOf<ButtonState>([
    'success',
    'info',
    'warning',
    'error',
    'neutral',
    'primary'
  ]),
  size: PropTypes.oneOf<ButtonSize>(['sm', 'md', 'lg']),
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Button;
