import { Field, useField } from 'react-final-form';
import PropTypes from 'prop-types';

import FormField, { FieldError, FieldLabel } from 'orm-react/components/Field';

import inputStyles from './styles.module.scss';
import { ReactNode } from 'react';

export interface FieldInputProps<FieldValue, InputValue> {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  format?: (value: FieldValue, name: string) => InputValue;
  allowNull?: boolean;
  info?: ReactNode;
}

const FieldInput = <FieldValue, InputValue>({
  name,
  type = 'text',
  label = '',
  placeholder,
  autoComplete = 'off',
  disabled = false,
  required = false,
  readOnly = false,
  allowNull,
  format,
  info
}: FieldInputProps<FieldValue, InputValue>) => {
  const { input } = useField(name);
  return (
    <FormField required={required} value={input.value}>
      <FieldError name={name} />
      <Field
        id={name}
        name={name}
        type={type}
        title={label || placeholder}
        component="input"
        className={inputStyles.field__input}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete={autoComplete}
        allowNull={allowNull}
        format={format}
        formatOnBlur
      />
      <FieldLabel name={name}>{label}</FieldLabel>
      {info}
    </FormField>
  );
};

FieldInput.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string
};

export default FieldInput;
