import { ToasterStatus } from 'orm-react/contexts/Toaster/types';

import './styles.module.scss';
import {
  ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
  FC
} from 'react';
import PropTypes from 'prop-types';

import Toaster from './Toaster';

import classNames from 'orm-react/helper/class-names';

const EVENT = typeof window === 'object' && new Event('showToaster');

const MESSAGES = new Map();

const ContextToaster = createContext({
  showToaster: (level: ToasterStatus, message: string): void => {
    // show toaster message
  }
});

const Messages = ({ className }: { className?: string }) => {
  const [, setUpdated] = useState(() => Date.now());

  const update: (msg: string) => void = (msg: string) => {
    MESSAGES.delete(msg);
    setUpdated(Date.now());
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('showToaster', update);

    // @ts-ignore
    return () => document.removeEventListener('showToaster', update);
  }, []);

  return (
    <div className={classNames('toaster', className)}>
      {Array.from(MESSAGES.keys()).map((key) => (
        <Toaster
          key={key}
          level={MESSAGES.get(key).level}
          message={MESSAGES.get(key).message}
          update={update}
        />
      ))}
    </div>
  );
};

Messages.propTypes /* remove-proptypes */ = {
  className: PropTypes.string
};

const ProviderToaster: FC<{
  children: ReactNode;
  className?: string;
}> = ({ className, children }) => {
  const showToaster = (level: ToasterStatus, msg: string) => {
    const key = `${level}${msg}`;
    if (!MESSAGES.has(key)) {
      MESSAGES.set(key, { level, message: msg });
      // @ts-ignore
      document.dispatchEvent(EVENT);
    }
  };

  return (
    <ContextToaster.Provider
      value={{
        showToaster
      }}
    >
      <>
        {children}
        <Messages className={className} />
      </>
    </ContextToaster.Provider>
  );
};

ProviderToaster.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

const useToaster = () => useContext(ContextToaster);

export { ContextToaster, ProviderToaster, useToaster, Toaster, ToasterStatus };
