export default function classNames(
  ...args: Array<string | boolean | number | undefined | null>
): string | undefined {
  return (
    args
      .reduce(
        (
          list: string[],
          clss:
            | string
            | boolean
            | number
            | ConcatArray<string>
            | undefined
            | null
        ): string[] =>
          // @ts-ignore
          list.concat(clss),
        []
      )
      .filter(Boolean)
      .join(' ')
      .replace(/\s{2,}/g, ' ')
      .trim() || undefined
  );
}
